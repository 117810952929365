<template>
  <div class="nav-header">
    <div class="container">
      <!-- 左侧  logo -->
      <div class="n-logo">
        <h2>北京伴一生科技有限公司</h2>
      </div>
      <!-- 右侧 导航 + 登录注册 -->
      <div class="n-r">
        <!-- 导航 -->
        <div class="n-list">
          <a href="/" class="active">网站首页</a>
          <a href="/#/ac1">行业资讯</a>
          <!-- <a href="/#/info">公司简介</a> -->
        </div>
        <!-- 登录注册 -->
        <div class="n-login">
          <span class="n-login n1" @click="goLogin()" v-show="this.LoggedShow">
            <i class="iconfont icon-denglu"></i>
            登录
          </span>

          <span
            class="n-login n2"
            @click="goRegister()"
            v-show="this.LoggedShow"
          >
            <i class="iconfont icon-zhuce"></i>
            注册
          </span>
          <!-- 用户名 -->
          <span
            class="n-info"
            v-show="!this.LoggedShow"
            v-text="this.nv_user.Username"
            ><i class="iconfont icon-denglu"></i
          ></span>

          <!-- 退出登录 -->
          <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
            <i class="iconfont icon-tuichudenglu"></i>
            退出登录
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },

    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      location.reload();
      // this.$forceUpdate()
    },

    // 跳转最新文章
    goAc1() {
      this.$router.push("/ac1");
    },
    // 跳转行业资讯
    goAc2() {
      this.$router.push("/ac2");
    },
    // 跳转独加新闻
    goAc3() {
      this.$router.push("/ac3");
    },
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议

    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-header {
  width: 100%;
  height: 80px;
  background: #fff;
  border-bottom: 1px solid #ccc;
  .container {
    height: 100%;
    @include flex();
    // 左侧
    .n-logo {
      h2 {
        font-size: 24px;
        color: $colorZ;
      }
    }
    // 右侧
    .n-r {
      height: 100%;
      display: flex;
      // 导航
      .n-list {
        width: 400px;
        height: 100%;
        // background: pink;
        display: flex;
        align-items: center;
        margin-right: 30px;
        a {
          display: inline-block;
          height: 100%;
          flex: 1;
          font-size: 16px;
          line-height: 100px;
          text-align: center;
          margin: 0 25px;
          color: #555;
          box-sizing: border-box;
          &.active {
            border-bottom: 3px solid $colorZ;
          }
          &:hover {
            border-bottom: 3px solid $colorZ;
          }
        }
      }

      // 登录注册
      .n-login {
        line-height: 100px;
        span {
          width: 100px;
          height: 35px;
          // background: #000;
          display: inline-block;
          margin: 0 10px;
          line-height: 35px;
          text-align: center;
          font-size: 14px;
          border: 3px solid $colorZ;
          color: $colorZ;
          border-radius: 30px;
          cursor: pointer;
          &.n2 {
            background-color: $colorZ;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
