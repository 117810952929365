<template>
  <div class="footer">
    <div class="container">
      <!-- 上层 -->
      <div class="f-s">
        <h1>北京伴一生科技有限公司</h1>
        <!-- <h3>电话：010-56623988</h3> -->
        <!-- <h3>邮箱：245778363@qq.com</h3> -->
        <h3>地址：北京市朝阳区将台乡东八间房村甲2号B区1层1208号</h3>
      </div>

      <div class="icp">
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
          >备案编号：******</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-footer",

  props: {
    email: String,
    phone: String,
    icp: String,
    name: String,
    addr: String,
  },
  methods: {
    // 跳转最新文章
    goAc1() {
      this.$router.push("/ac1");
    },
    // 跳转行业资讯
    goAc2() {
      this.$router.push("/ac2");
    },
    // 跳转独加新闻
    goAc3() {
      this.$router.push("/ac3");
    },
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    goHome() {
      this.$router.push("/index");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.footer {
  height: 200px;
  width: 100%;
  background: #21272f;
  .container {
    height: 100%;
    padding-top: 10px;
    box-sizing: border-box;
    .f-s {
      width: 100%;
      height: 150px;
      // background-color: #fff;
      border-bottom: 1px solid #fff;
      text-align: center;
      h1 {
        font-size: 21px;
        color: #fff;
      }
      h3 {
        font-size: 16px;
        color: #fff;
        margin: 10px 0;
      }
    }
    .icp {
      width: 100%;
      height: auto;
      line-height: 35px;
      text-align: center;
      a {
        font-size: 12px;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
</style>
