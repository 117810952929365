<template>
  <div class="index">
    <!-- banner 区块 -->
    <div class="banner">
      <img src="/imgs/b1.jpg" alt="" />
    </div>
    <!-- 定宽 -->
    <div class="container">
      <!-- 关于我们区块  sr-->
      <div class="us-title">
        <h2>关于我们</h2>
        <h4>ABOUT US</h4>
      </div>
      <div class="us-con">
        <!-- 左侧 -->
        <div class="us-l">
          <img src="/imgs/us.jpg" alt="" />
        </div>
        <!-- 右侧 -->
        <div class="us-r">
          <p>
            北京伴一生科技有限公司成立于2022年12月27日，注册地位于北京市朝阳区将台乡东八间房村甲2号B区1层1208号，经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；软件开发；软件销售；数字文化创意软件开发；信息技术咨询服务；平面设计；广告设计、代理；广告制作；工艺美术品及收藏品零售（象牙及其制品除外）；玩具销售；母婴用品销售；日用杂品销售；箱包销售；服装服饰零售；文具用品零售；化妆品零售；珠宝首饰零售；针纺织品销售；电子产品销售；钟表与计时仪器销售；家用电器销售；鞋帽零售；旅客票务代理；宠物食品及用品零售；票务代理服务；互联网销售（除销售需要许可的商品）。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：第二类增值电信业务；互联网信息服务。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）（不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
          </p>
        </div>
      </div>
      <!-- 关于我们区块 end -->

      <!-- 资讯区块 -->
      <at1></at1>
    </div>

    <modal title="请选择支付方式" btnType="1" modalType="middle" :showModal="showModal" @submit="showModal = false"
      @cancel="showModal = false">
      <template v-slot:body>
        <div class="m-l">
          <img src="/imgs/wx.png" />
          <p>微信</p>
        </div>

        <div class="m-l">
          <img src="/imgs/zxb.png" />
          <p>支付宝</p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal";
import at1 from "./../components/at1";
export default {
  name: "index",
  components: {
    Modal,
    at1
  },
  data() {
    return {
      href: "index.html",
      state: JSON.parse(window.localStorage.getItem("state")),
      showModal: false,
      ac1: [],
      ac2: [],
      ac3: [],
      ac4: [],
      ac5: [],
    };
  },
  mounted() {
    this.init1();
    document.title = "北京伴一生科技有限公司";
  },
  methods: {
    init1() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.ac1 = data.slice(0, 4);
        this.ac2 = data.slice(4, 8);
        this.ac3 = data.slice(4, 7);
        this.ac4 = data.slice(10, 25);
        this.ac5 = data.slice(6, 10);
      });
    },

    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      //   console.log(this.showModal);
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每条3元，点击确定注册购买");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.index {

  // banner
  .banner {
    width: 100%;
    height: 500px;
    background-color: pink;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 定宽
  .container {

    // 关于我们区块
    .us-title {
      width: 100%;
      height: 40px;
      border-left: 4px solid $colorZ;
      padding-left: 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      margin: 20px auto;

      h2 {
        font-size: 21px;
        color: #555;
      }

      h4 {
        font-size: 12px;
        color: #888;
      }
    }

    // 容器
    .us-con {
      height: 400px;
      width: 100%;
      // background-color: pink;
      // @include flex();
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px auto;

      // 左侧
      .us-l {
        width: 60%;
        height: 100%;

        // background: #c60023;
        img {
          width: 100%;
          height: 100%;
        }
      }

      // 右侧
      .us-r {
        width: 40%;
        height: 100%;
        padding: 30px;
        box-sizing: border-box;
        border-bottom: 2px solid $colorZ;

        p {
          font-size: 14px;
          line-height: 24px;
          text-indent: 40px;
        }
      }
    }
  }
}
</style>
