<template>
  <div class="info">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

 
    <!-- 定宽 -->
    <div class="container">
      <!-- 左侧 导航 -->
      <div class="info-1">
        <ul>
          <li @click="goInfo()">关于我们</li>
          <li class="active">用户协议</li>
        </ul>
      </div>
      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>用户协议</h3>
        <!-- 下环线 -->
        <div class="bink"></div>

        <!-- banenr -->
        <div class="i2-banner"></div>

        <div class="i2-con">
      
          <p>1、帐号的取得</p>
          <p>
            (1)您确认，在您开始注册前，已经具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
          </p>
          <p>
            (2)您注册成功后，即成为注册用户，用户须对在的注册信息的真实性、合法性、有效性承担全部责任。您可自行创建、修改昵称，但用户名和昵称的命名及使用应遵守相关法律法规并符合网络道德，不得冒充他人或恶意注册使人误认；不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；用户名和昵称中不能含有任何侮辱、诽谤、淫秽或暴力等侵害他人合法权益或违反公序良俗的词语。如您违反前述规定，有权随时限制或拒绝您使用该账号，甚至注销该账号。
          </p>
          <p>(3)用户名的管理</p>
          <p>
            1)请勿以党和国家领导人或其他名人的真实姓名、字、号、艺名、笔名、头衔等注册和使用昵称（如确为本人，需要提交相关证据并通过审核方可允许使用）；
          </p>
          <p>
            2)请勿以国家组织机构或其他组织机构的名称等注册和使用昵称（如确为该机构，需要提交相关证据并通过审核方可允许使用）；
          </p>
          <p>3)请勿注册和使用与其他网友相同、相仿的用户名或昵称；</p>
          <p>4)请勿注册和使用不文明、不健康的用户名和昵称；</p>
          <p>
            5)请勿注册和使用易产生歧义、引起他人误解或带有各种奇形怪状符号的用户名和昵称。
          </p>
          <p>
            6)用户以虚假信息骗取账号名称注册，或账号头像、简介等注册信息存在违法和不良信息的，将暂停或注销。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "info",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "北京清北学有方教育科技有限公司";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.info {
  width: 100%;
  background-color: #f0f0f0;
  // banner
  .i-banner {
    width: 100%;
    height: 250px;
    background-color: pink;
    margin-bottom: 120px;
  }

  // 定宽布局
  .container {
    // @include flex();
    display: flex;
    justify-content: space-between;
    // height: 2000px;
    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;
      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;
        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;
          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }
    // 右侧
    .info-2 {
      width: 70%;
      height: 100%;
      background: #fff;
      padding-top: 20px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 60px;
      h3 {
        font-size: 24px;
      }
      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
         background: url('/imgs/b1.jpg') no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        // width: 600px;
        text-align: left;
        padding: 0 20px;
        box-sizing: border-box;
        p {
          font-size: 16px;
          line-height: 1.3em;
          color: #666;
          margin: 15px auto;
        }
      }
    }
  }
}
</style>
