<template>
  <div class="at1">
    <!-- 标题 -->
    <div class="at-title">
      <h2>资讯</h2>
      <h4>INDUSTRY INFORMATION</h4>
    </div>
    <!-- 循环项 -->
    <div class="at-item" @click="gopay()">
      <!-- 左侧图片 -->
      <div class="item-l">
        <img src="/imgs/1.jpg" alt="" />
      </div>
      <!-- 右侧信息 -->
      <div class="item-r">
        <!-- 标题 -->
        <div class="item-title">未来30年，中国互联网路向何方？</div>
        <!-- 文本 -->
        <p>
          1994 年 4 月 20 日，中国通过一条 64K 的国际专线全功能接入国际互联网，到今年已是 27 年整。在这 27 年中，互联网逐渐成为影响中国社会发展的重要基础设施之一，它改变了社会的流向、商业的模式、产业的形态，以及万千中国人的人生轨迹。。。
        </p>
        <!-- 信息层 -->
        <div class="item-info">
          <span>2022-5-31</span>
          <span><i class="iconfont icon-dianzan"></i>243</span>
        </div>
      </div>
    </div>
    <!-- 循环项 -->
    <div class="at-item" @click="gopay()">
      <!-- 右侧信息 -->
      <div class="item-r">
        <!-- 标题 -->
        <div class="item-title">以码抗病：计算机如何辅助抗病毒药物研发</div>
        <!-- 文本 -->
        <p>
         对病毒各类蛋白的调研任务分配到远在五湖四海的每个实验室成员头上。没有瓶瓶罐罐，没有五花八门的试剂，没有昂贵精致的仪器。一台能远程连上服务器的电脑，这便是我所拥有的全部。。。
        </p>
        <!-- 信息层 -->
        <div class="item-info">
          <span>2022-5-25</span>
          <span><i class="iconfont icon-dianzan"></i>210</span>
        </div>
      </div>
      <!-- 左侧图片 -->
      <div class="item-l">
        <img src="/imgs/2.jpg" alt="" />
      </div>
    </div>
    <!-- 循环项 -->
    <div class="at-item" @click="gopay()">
      <!-- 左侧图片 -->
      <div class="item-l">
        <img src="/imgs/3.jpg" alt="" />
      </div>
      <!-- 右侧信息 -->
      <div class="item-r">
        <!-- 标题 -->
        <div class="item-title">在量子计算机到来之前，请准备好抗量子破解的密码学</div>
        <!-- 文本 -->
        <p>
          当实用的量子计算机最终来临的时候，它将能够破解一直在为我们的在线隐私、政府安全、公司安全以及几乎所有互联网使用者的个人安全保驾护航的标准数字密码。。。
        </p>
        <!-- 信息层 -->
        <div class="item-info">
          <span>2022-5-23</span>
          <span><i class="iconfont icon-dianzan"></i>243</span>
        </div>
      </div>
    </div>
    <!-- 循环项 -->
    <div class="at-item" @click="gopay()">
      <!-- 右侧信息 -->
      <div class="item-r">
        <!-- 标题 -->
        <div class="item-title">谷歌量子计算机运算200秒=世界第一超算运算10000年</div>
        <!-- 文本 -->
        <p>
          这或许是计算机领域的一个里程碑事件：谷歌宣称“量子霸权”已经实现，他们首次在实验中证明了量子计算机对于传统架构计算机的优越性：在世界第一超算 Summit 需要计算 1 万年的实验中，谷歌的量子计算机只用了 3 分 20 秒。。。
        </p>
        <!-- 信息层 -->
        <div class="item-info">
          <span>2022-5-19</span>
          <span><i class="iconfont icon-dianzan"></i>147</span>
        </div>
      </div>
      <!-- 左侧图片 -->
      <div class="item-l">
        <img src="/imgs/4.jpg" alt="" />
      </div>
    </div>
    <!-- 循环项 -->
    <div class="at-item" @click="gopay()">
      <!-- 左侧图片 -->
      <div class="item-l">
        <img src="/imgs/5.jpg" alt="" />
      </div>
      <!-- 右侧信息 -->
      <div class="item-r">
        <!-- 标题 -->
        <div class="item-title">大数据“杀熟”怎么破？专家解读</div>
        <!-- 文本 -->
        <p>
          大数据“杀熟”，是指在线经营者利用大数据等技术手段，针对不同消费特征的消费者，对同一产品或服务在相同条件下设置差异化的价格。这其中包括三个要件：一是大数据识别消费者，二是差异化定价，三是产品或服务同一。其实质就是卖方摸清买方承受能力后，最大限度榨取其消费者剩余。。。
        </p>
        <!-- 信息层 -->
        <div class="item-info">
          <span>2022-5-15</span>
          <span><i class="iconfont icon-dianzan"></i>319</span>
        </div>
      </div>
    </div>

    <modal
      title="请付款阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <h2>微信支付：3元</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      mAc: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.axios.get("/mock/index.json").then((res) => {
        const data = res.data;
        this.ac1 = data.slice(0, 5);
        this.mAc = data.slice(4, 8);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      alert("资讯每条3元，点击确定购买");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费3元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.at1 {
  // height: 1500px;
  //   background: pink;
  margin: 30px auto;
  //   标题
  .at-title {
    width: 100%;
    height: 40px;
    border-left: 4px solid $colorZ;
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin: 20px auto;
    h2 {
      font-size: 21px;
      color: #555;
    }
    h4 {
      font-size: 12px;
      color: #888;
    }
  }
  //   循环项
  .at-item {
    width: 100%;
    height: 350px;
    @include flex();
    padding: 20px;
    box-sizing: border-box;
    transition: all 0.3s;
    margin: 28px auto;
    &:hover {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    }
    &:hover .item-title {
      color: $colorZ;
    }
    // 左侧
    .item-l {
      width: 50%;
      height: 100%;
      //   background: #c60023;
      img {
        width: 100%;
        height: 100%;
      }
    }
    // 右侧
    .item-r {
      width: 50%;
      height: 100%;
      //   background-color: #ff6600;
      padding: 20px;
      box-sizing: border-box;
      //   标题
      .item-title {
        font-size: 16px;
        font-weight: bold;
        margin: 20px 0;
        transition: all 0.3s;
      }
      //   文本
      p {
        font-size: 14px;
        text-indent: 30px;
        color: #555;
        line-height: 35px;
        margin: 35px auto;
      }
      //   信息层
      .item-info {
        width: 100%;
        height: 40px;
        @include flex();
        span {
          font-size: 13px;
          color: #666;
          i {
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
