<template>
  <div class="info">
    <!-- banner -->
    <div class="banner">
      <img src="/imgs/b1.jpg" alt="" />
    </div>
    <div class="container">
      <!-- 标题 -->
      <div class="at-title">
        <h2>公司简介</h2>
        <h4>Company profile</h4>
      </div>

      <div class="info-con">
        <p>
          北京惟视嘉医疗器械有限公司成立于2020年01月15日，注册地位于北京市朝阳区东三环北路霞光里18号1号楼B座3层309B单元，经营范围包括销售III类医疗器械；销售I类医疗器械、II类医疗器械、日用品、眼镜、针纺织品、家用电器、电子产品、花卉、新鲜水果、新鲜蔬菜、工艺品、文具用品、体育用品、化妆品、通讯设备、计算机、软件及辅助设备、厨房用具、卫生间用具、服装、鞋帽、箱包、工艺品；软件开发；应用软件服务（不含医用软件）；计算机系统服务；基础软件服务；技术开发、技术转让、技术推广、技术服务、技术咨询；验光配镜（不含诊疗活动）；健康管理（须经审批的诊疗活动除外）；健康咨询（须经审批的诊疗活动除外）；
          食品经营（仅销售预包装食品）。（市场主体依法自主选择经营项目，开展经营活动；销售III类医疗器械以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  components: {},
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = " 北京惟视嘉医疗器械";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.info {
  .banner {
    width: 100%;
    height: 500px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    //   标题
    .at-title {
      width: 100%;
      height: 40px;
      border-left: 4px solid $colorZ;
      padding-left: 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      margin: 20px auto;
      h2 {
        font-size: 21px;
        color: #555;
      }
      h4 {
        font-size: 12px;
        color: #888;
      }
    }
    .info-con {
      p {
        font-size: 14px;
        line-height: 70px;
        color: #555;
        text-indent: 40px;
        background-color: #fff;
        margin: 40px 0;
      }
    }
  }
}
</style>
